body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #222;
    color: #d3d3d3;
}

* {
    box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
}

ul {
    padding-left: 1rem;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
    border-radius: 0.5rem;
    border: none;
    background: #735cf3;
    color: white;
    box-shadow: black 0 0 7px -2px;
}

button:focus {
    outline: 4px solid #5e49c5;
}

button:hover {
    background: #7c65f3;
}

button:active {
    background: #7c65f3;
    box-shadow: #332770 0 0 7px -3px;
}

hr {
    border: 1px solid #444;
}

.btn-group {
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: fixed;
    bottom: 3rem;
    left: 0;
    right: 0;
    margin: auto;
    padding: 1rem;
    width: 100%;
    max-width: 40rem;
}

.back-btn, .placeholder-btn {
    border-radius: 50%;
    height: 5rem;
    width: 5rem;
    opacity: 0.5;
    background: #5d5d5d;
}

.placeholder-btn {
    visibility: hidden;
}

.main-btn {
    height: 10rem;
    width: 10rem;
    border-radius: 50%;
}

.sources {
    display: flex;
}

.source-checkbox {
    display: flex;
    margin-right: 1rem;
    font-size: 80%;
    align-items: center;
}


